.delete-account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
  
  .block {
    background: linear-gradient(to right, #4e54c8, #8f94fb);
    border: 2px solid #ff6b6b;
    padding: 20px;
    margin: 20px;
    text-align: center;
    border-radius: 10px;
    width: 30%; /* Set width to 30% */
    display: flex; /* Added */
    flex-direction: column; /* Added */
    justify-content: center; /* Added */
    align-items: center;
  }
  
  .block h2 {
    margin-bottom: 10px;
  }
  
  .block p {
    margin-bottom: 20px;
  }
  
  .error {
    color: rgb(115, 7, 7);
    margin-top: 10px;
  }
  
  button {
    background-color: #1b1212;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #ff9a9e;
  }
  
  .progress-indicator {
    margin-top: 20px;
  }
  
  .countdown {
    margin-top: 20px;
  }
  
  .phone-input-container {
    display: flex;
    align-items: center;
  }
  
  .react-phone-number-input__input,
  .react-phone-number-input__country {
    height: 40px;
    font-size: 16px;
    padding: 5px 10px;
  }
  
  .react-phone-number-input__country {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 1px solid #ccc;
    background-color: #fff;
  }
  
  .otp-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .otp-input-container input {
    width: 40px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    margin: 0 5px;
  }

  .delete-account-warning {
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 0px;
}

.warning-header {
    font-size: 14px;
    margin-bottom: 10px;
}

.warning-content {
    font-size: 12px;
}

.warning-content ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
}

.warning-content ul li {
    margin-bottom: 10px;
}

.warning-content p {
    margin-bottom: 10px;
}

  