/* Home.css */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Center vertically */
    text-align: center; /* Center text horizontally */
}

.home-title {
    margin-bottom: 20px; /* Add spacing below the title */
}

.home-description {
    max-width: 600px; /* Set maximum width for description text */
}
