/* ContactUs.css */
.contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Center vertically */
}

.contact-us-title {
    margin-bottom: 20px; /* Add spacing below the title */
}

.contact-us-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-us-input,
.contact-us-textarea {
    margin-bottom: 10px; /* Add spacing between inputs */
    padding: 10px;
    width: 300px; /* Set width of inputs */
}

.contact-us-button {
    padding: 10px 20px; /* Add padding to button */
    background-color: #4e54c8; /* Button background color */
    color: white; /* Text color */
    border: none; /* Remove border */
    cursor: pointer; /* Change cursor on hover */
}

.contact-us-button:hover {
    background-color: #8f94fb; /* Button background color on hover */
}
