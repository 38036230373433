/* Header.css */
.header {
  background: linear-gradient(to right, #4e54c8, #8f94fb); /* Gradient background */
  padding: 20px; /* Add some padding for better spacing */
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Center items vertically */
}

.main-title {
  margin-right: auto; /* Push the main title to the left */
  color: white; /* Set text color to white */
}

.nav-list {
  list-style-type: none; /* Remove default list styling */
  margin: 0;
  padding: 0;
  display: flex; /* Display items in a row */
  justify-content: flex-end; /* Align items to the end of the flex container */
}

.nav-list li {
  margin-left: 10px; /* Add spacing between navigation items */
}

.nav-list li a {
  text-decoration: none; /* Remove underline from links */
  color: white; /* Set text color to white */
}
